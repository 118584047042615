<template>
	<screen class="donor-list-view" size="large">
		<div slot="title">
			<div
				:style="{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center'
				}"
			>
				<h1>Donors</h1>
				<ui-button
					@click="showFilter = !showFilter"
					:style="{ marginLeft: '15px', marginTop: '2px' }"
				>
					<i class="fas fa-filter"></i>
					Show/Hide Columns
				</ui-button>
			</div>
			<div
				v-if="messageResult"
				class="roundup-alert roundup-alert-info"
				style="margin-top: 20px"
			>
				{{ messageResult }}
			</div>
		</div>
		<template slot="buttons">
			<ui-button @click="handleDownload">Export CSV</ui-button>
		</template>
		<el-table
			slot="table"
			:data="donors"
			:style="{ width: '100%', overflowX: 'scroll' }"
		>
			<el-table-column
				v-if="shownColumns.includes('first_name')"
				prop="first_name"
				width="120"
				label="First Name"
			>
			</el-table-column>
			<el-table-column
				v-if="shownColumns.includes('last_name')"
				prop="last_name"
				width="120"
				label="Last Name"
			>
			</el-table-column>
			<el-table-column
				v-if="shownColumns.includes('phone')"
				prop="phone"
				width="200"
				label="Phone"
			>
			</el-table-column>
			<el-table-column
				v-if="shownColumns.includes('email')"
				prop="email"
				label="Email"
				width="200"
			>
			</el-table-column>
			<el-table-column
				v-if="shownColumns.includes('maximum_donation')"
				prop="maximum_donation"
				label="Cap"
				width="100"
			>
				<template slot-scope="scope">
					{{
						numberFormat(
							get(scope, 'row.maximum_donation', 'None') / 100
						)
					}}
				</template>
			</el-table-column>
			<el-table-column
				v-if="shownColumns.includes('completed_signup_at')"
				prop="completed_signup_at"
				label="Signup Date"
				min-width="120"
			>
				<template slot-scope="scope">
					<template v-if="scope.row.completed_signup_at">
						{{
							scope.row.completed_signup_at | moment('MM/DD/YYYY')
						}}
					</template>
					<template v-else>
						{{ incompleteStep(scope.row) }}
					</template>
				</template>
			</el-table-column>
			<el-table-column
				v-if="shownColumns.includes('is_donating')"
				prop="is_donating"
				label="Paused"
			>
				<template slot-scope="scope">
					{{
						scope.row.has_completed_signup && !scope.row.is_donating
							? 'Yes'
							: ''
					}}
				</template>
			</el-table-column>
			<el-table-column align="right" label=" ">
				<template slot-scope="scope">
					<el-button
						v-if="!scope.row.completed_signup_at && scope.row.phone"
						@click="openConfirm(scope.row)"
						size="mini"
						>Send Reminder</el-button
					>
					<el-button
						@click="navigateToDonorDetail(scope.row)"
						v-else
						size="mini"
						>Details</el-button
					>
					<ui-loader :loading="messageLoading" />
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			@current-change="pageChange"
			:current-page="page"
			:page-size="perPage"
			layout="prev, pager, next, total"
			:total="total"
		>
		</el-pagination>

		<modal v-model="showFilter">
			<h2>Filter by Columns</h2>
			<ui-label
				v-for="(item, index) in allColumns"
				:key="index"
				v-bind:style="{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center'
				}"
			>
				<ui-toggle
					v-bind:style="{ flex: 1 }"
					:key="index"
					@input="filterColumns(item)"
					:checked="shownColumns.includes(item)"
				>
				</ui-toggle>
				<p v-bind:style="{ flex: 4 }">{{ keyMap[item] }}</p>
			</ui-label>
		</modal>
		<modal v-model="showTemplateModal" size="large">
			<h2>Confirm Message</h2>
			<div class="token-scroll-horizontal">
				<p style="margin: 0; marginbottom: 5px; marginleft: 5px">
					Personalization Tokens (click to copy)
					<span style="fontweight: 900" v-if="copied">
						- Token Copied to clipboard</span
					>
				</p>
				<p class="token tooltip" @click="copyToken('{{first_name}}')">
					First Name
				</p>
				<p class="token tooltip" @click="copyToken('{{last_name}}')">
					Last Name
				</p>
				<p
					class="token tooltip"
					@click="copyToken('https://roundupapp.com/#/Login')"
				>
					Login Link
				</p>
				<p
					class="token tooltip"
					@click="copyToken('https://roundupapp.com/#/Login/card')"
				>
					Add/Update Credit Card Link
				</p>
				<p
					class="token tooltip"
					@click="
						copyToken('https://roundupapp.com/#/Login/pre-plaid')
					"
				>
					Add/Update Bank Account Link
				</p>
				<p
					class="token tooltip"
					@click="
						copyToken('https://roundupapp.com/#/Login/mindonation')
					"
				>
					Set Monthly Minimum Link
				</p>
			</div>
			<div :style="{ display: 'flex', flexDirection: 'row' }">
				<ui-label :style="{ flex: 4 }">
					<ui-input
						type="textarea"
						id="autoresizing"
						v-model="reminderTemplate.message"
						placeholder="Remind Users to complete their account setup (SMS)"
					/>
				</ui-label>
			</div>
			<ui-button :loading="messageLoading" @click="sendMessage"
				>Send</ui-button
			>
			<br />
			<input type="checkbox" v-model="skipModal" />Automatically use this
			tempalte in the future? You can always edit this message in Settings
			> SMS Templates.
		</modal>
	</screen>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { get, numberFormat, incompleteStep, enums } from '@/utilities'
import cloneDeep from 'lodash/cloneDeep'
import table from '@/mixins/table'
import download from '@/mixins/download'
import Modal from '../../ui/Modal'

import UiButton from '../../ui/Button'
import UiToggle from '../../ui/Toggle'
import UiLoader from '../../ui/Loader'
import UiLabel from '../../ui/Label'
import UiInput from '../../ui/Input'
import TokenList from '../../layout/TokenList'

import Screen from '../../ui/Screen'

export default {
	name: 'donor-list-view',
	components: {
		UiButton,
		Screen,
		Modal,
		UiToggle,
		UiLabel,
		TokenList,
		UiLoader,
		UiInput
	},
	mixins: [table, download],
	data() {
		return {
			shownColumns: [
				'first_name',
				'last_name',
				'phone',
				'email',
				'is_donating',
				'completed_signup_at'
			],
			allColumns: [
				'first_name',
				'last_name',
				'phone',
				'email',
				'is_donating',
				'maximum_donation',
				'completed_signup_at'
			],
			showFilter: false,
			keyMap: {
				first_name: 'First Name',
				last_name: 'Last Name',
				phone: 'Phone',
				email: 'Email',
				is_donating: 'Paused',
				maximum_donation: 'Cap',
				completed_signup_at: 'Signup Date'
			},
			user: null,
			skipModal: false,
			showTemplateModal: false,
			reminderTemplate:
				`Hi {{first_name}}, thank you so much for giving to us via RoundUp App. It looks you haven't finished setup. Please do so... every roundup helps  us ${this.$store.state.institution.item.full_impact_statement}. Click the link to finish https://app.roundupapp.com/#/login -` +
				this.$store.state.institution.item.name,
			isSaving: false,
			messageLoading: false,
			messageError: '',
			messageResult: '',
			copied: false
		}
	},
	computed: {
		...mapState({
			donors: ({ donorList }) => donorList.items,
			total: ({ donorList }) => donorList.total,
			authToken: ({ me }) => me.token,
			me: ({ me }) => me
		})
	},
	head: {
		title: {
			inner: 'Donors'
		}
	},
	methods: {
		...mapActions(['getDonors', 'getTemplates', 'sendSMS']),
		navigateToDonorDetail(donor) {
			this.$store.commit('DONOR_DETAIL', { result: donor })
			let donorId = donor.id
			if (localStorage.demo && localStorage.demo !== 'false') {
				donorId = -1
			}

			this.$router.push({
				name: 'donors.detail',
				params: { donorId: donorId },
				query: {
					first_name: donor.first_name,
					last_name: donor.last_name,
					created_at: new Date(donor.created_at).getTime()
				}
			})
		},
		paramsChanged(params) {
			this.getDonors(params)
		},
		handleDownload() {
			window.analytics.track('download_donor_report')
			var params = this.getParameters()
			params.perPage = 1000
			params.page = 0
			this.postParameters('/donors/csv', params, this.authToken)
		},
		filterColumns(column) {
			const idx = this.shownColumns.indexOf(column)
			if (idx >= 0) {
				this.shownColumns.splice(idx, 1)
			} else {
				this.shownColumns.push(column)
			}
		},
		openConfirm(user) {
			this.user = user
			if (!this.skipModal) {
				this.showTemplateModal = true
			} else {
				this.sendMessage()
			}
		},
		sendMessage() {
			window.analytics.track('send_sms_notify', {
				institution_id: this.me.institution_id,
				admin_id: this.me.id
			})
			this.messageLoading = true
			this.sendSMS({
				userId: this.user.id,
				message: this.reminderTemplate.message,
				phone: this.user.phone.replace('[^0-9]', '')
			})
				.then(mid => {
					this.messageLoading = false
					this.messageResult = 'Your message has been sent'
					this.showTemplateModal = false
					setInterval(() => {
						this.messageResult = ''
					}, 3000)
				})
				.catch(({ response }) => {
					this.messageResult = 'Error: ' + response.data.message
					this.messageLoading = false
					this.$swal('Error', response.data.message)
				})
		},
		async copyToken(value) {
			if (!navigator.clipboard) {
				// Clipboard API not available
				return
			}
			try {
				await navigator.clipboard.writeText(value)
				this.copied = true
				setTimeout(() => {
					this.copied = false
				}, 1000)
			} catch (err) {
				console.error('Failed to copy!', err)
			}
		},
		get,
		numberFormat,
		incompleteStep
	},
	mounted() {
		this.getDonors(this.getParameters())
		this.getTemplates().then(list => {
			const rmTemp = list.filter(
				t => t.template_id === enums.templates.smsReminder
			)[0]
			if (rmTemp) {
				this.reminderTemplate = cloneDeep(rmTemp)
			}
		})
	}
}
</script>

<style lang="scss">
@import url('//unpkg.com/element-ui@2.13.2/lib/theme-chalk/index.css');
@import '@roundupapp/component-library/src/styles/index';

.token-scroll-horizontal {
	overflow-x: scroll;
	flex-direction: row;
	background: $roundup-light-blue-opaque;
	padding-top: 10px;
	position: relative;
	.token {
		padding: 8px 3px;
		background: $roundup-light-grey;
		margin-left: 5px;
		text-align: center;
		align-content: center;
		justify-content: center;
		margin-right: 5px;
		margin-top: 0px;
		margin-bottom: 3px;
		display: inline-flex;
		min-width: 150px;
		font-size: 10px;
		&:hover {
			cursor: pointer;
		}
		&:active {
			color: #000;
		}
	}
	.tooltip {
		position: relative;
		.tooltiptext {
			visibility: hidden;
			margin-right: 20px;
			margin-bottom: 20px;
			width: 150px;
			top: 110%;
			left: 50%;
			margin-left: -75px;
			background-color: #fff;
			color: #000;
			font-size: 12px;
			text-align: center;
			border-radius: 6px;
			padding: 5px;
			boxshadow: 0 10px 20px rgba(0, 0, 0, 0.1);
			border: 2px solid $roundup-light-grey;
			position: absolute;
			z-index: 1;
		}
		&:hover {
			.tooltiptext {
				visibility: visible;
			}
		}
	}
	::-webkit-scrollbar {
		-webkit-appearance: none;
		height: 2px;
	}
	::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background-color: $roundup-primary;
		box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
	}
}
</style>
